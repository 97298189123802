<template>
  <div class="partners">
    <div class="partners-info">
      <h2 class="partners-title">f(x)Wallet Connects You with</h2>
      <div class="partners-btn">
        <CButton type="comic" :height="42" :minWidth="'225px'" @click="goOut">View f(x) Ecosystem</CButton>
      </div>
    </div>
    <div class="partners-list-box">
      <div class="partners-list" v-for="(item, key) in listData" :key="key">
        <a
          class="partners-list-item"
          :href="child.url"
          target="_blank"
          rel="noopener noreferrer"
          v-for="(child, index) in [...item, ...item]"
          :key="index"
        >
          <img :src="require(`@/assets/images/ecosystem/${child.icon}`)" alt="" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import CButton from '@/components/common/CButton.vue'
import { mapState } from 'vuex'

export default {
  name: 'Partners',
  components: { CButton },
  data() {
    return {
      list: [
        ['logo_arbitrum.png', 'logo_avalanche.png', 'logo_bitcoin.png', 'logo_bnbchain.png', 'logo_cosmos.png'],
        [
          'logo_osmosis.png',
          'logo_polygon.png',
          'logo_pudinxchain.png',
          'logo_solana.png',
          'logo_tron.png',
          'logo_walletconnect.png'
        ],
        ['logo_ethereum.png', 'logo_f(x)core.png', 'logo_foxgaming.png', 'logo_marginx.png', 'logo_optimism.png']
      ]
    }
  },
  computed: {
    ...mapState(['ecosystemData']),
    listData() {
      const urlByLogo = Object.values(this.ecosystemData)
        // return Object.values(this.ecosystemData)
        .reduce((previousValue, currentValue) => {
          return [...previousValue, ...currentValue]
        }, [])
        .reduce((previousValue, currentValue) => {
          previousValue[currentValue.logo] = currentValue.url
          return previousValue
        }, {})
      return this.list.map(item => {
        return item.map(child => {
          return {
            url: urlByLogo[child],
            icon: child
          }
        })
      })
    }
  },
  methods: {
    goOut() {
      // window.open('/')
      this.$router.push('/ecosystem')
    }
  }
}
</script>

<style scoped lang="scss">
.partners {
  width: var(--content-width);
  margin-left: auto;
  margin-right: auto;
  padding: 156px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .partners-info {
    padding-top: 32px;
    padding-left: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 384px;
  }
  .partners-title {
    width: 352px;
  }
  .partners-btn {
    margin-bottom: 24px;
  }
  .partners-list-box {
    width: 856px;
    padding-left: 48px;
    padding-right: 32px;
    height: 384px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 72px;
      left: 0;
      z-index: 1;
    }
    &::before {
      top: 0;
      background-image: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    }
    &::after {
      bottom: 0;
      background-image: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    }
  }
  .partners-list {
    &:nth-of-type(2n + 1) {
      animation: scroll 6s linear infinite;
    }
    &:nth-of-type(2n) {
      animation: scroll 6s linear reverse infinite;
    }
    &:hover {
      animation-play-state: paused;
    }
  }
  .partners-list-item {
    display: block;
    width: 224px;
    padding: 12px 32px;
    background-color: #fbfbfb;
    border-radius: 4px;
    position: relative;
    transition: box-shadow 0.3s, background-color 0.3s, border-radius 0.3s;
    &:not(:nth-of-type(1)) {
      margin-top: 32px;
    }
    &::before {
      content: '';
      position: absolute;
      top: -50%;
      left: -50%;
      right: -50%;
      bottom: -50%;
      border-radius: 8px;
      border: 1px solid transparent;
      transform: scale(0.5);
      transition: border 0.3s;
    }
    &:hover {
      box-shadow: 0px 8px 20px 0px rgba(8, 10, 50, 0.06);
      background-color: #fff;
      border-radius: 4px;
      &::before {
        border-color: rgba(5, 19, 46, 0.08);
      }
    }
  }
}
@keyframes scroll {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(calc(-50% - 16px));
  }
}
</style>
