var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"world"},[_c('div',{staticClass:"world-elevator"},[_c('div',{staticClass:"world-content"},[_c('h2',{staticClass:"world-title"},[_vm._v("The most decentralized way to access web3 world")]),_c('div',{staticClass:"world-page world-page-1",class:{ active: _vm.pageActive === 0, up: 0 < _vm.pageActive, down: 0 > _vm.pageActive }},[_c('div',{staticClass:"world-page-img"},_vm._l((_vm.imgListMap[0]),function(item){return _c('div',{key:item.url,staticClass:"world-page-img-item",class:[
              { active: _vm.listActive0 === item.key, up: item.key > _vm.listActive0, down: item.key < _vm.listActive0 },
              item.upDown,
              item.delay
            ]},[_c('img',{attrs:{"src":require(`@/assets/images/wallet/${item.url}`),"alt":""}})])}),0),_c('ul',{staticClass:"world-page-list"},_vm._l((_vm.list[0]),function(item,key){return _c('li',{key:item.title,staticClass:"world-page-list-item",class:{ active: _vm.listActive0 === key },on:{"click":function($event){_vm.listActive0 = key},"mouseenter":_vm.stopTimeOut,"mouseleave":_vm.timeOut}},[_c('div',{staticClass:"world-page-list-bg",class:{ up: key > _vm.listActive0, down: key < _vm.listActive0 }}),_c('h3',{staticClass:"world-page-list-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"world-page-list-note common-text"},[_vm._v(_vm._s(item.note))])])}),0)]),_c('div',{staticClass:"world-page world-page-2",class:{ active: _vm.pageActive === 1, up: 1 < _vm.pageActive, down: 1 > _vm.pageActive }},[_c('ul',{staticClass:"world-page-list"},_vm._l((_vm.list[1]),function(item,key){return _c('li',{key:item.title,staticClass:"world-page-list-item",class:{ active: _vm.listActive1 === key },on:{"click":function($event){_vm.listActive1 = key},"mouseenter":_vm.stopTimeOut,"mouseleave":_vm.timeOut}},[_c('div',{staticClass:"world-page-list-bg",class:{ up: key > _vm.listActive1, down: key < _vm.listActive1 }}),_c('h3',{staticClass:"world-page-list-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"world-page-list-note common-text"},[_vm._v(_vm._s(item.note))])])}),0),_c('div',{staticClass:"world-page-img"},_vm._l((_vm.imgListMap[1]),function(item){return _c('div',{key:item.url,staticClass:"world-page-img-item",class:[
              { active: _vm.listActive1 === item.key, up: item.key > _vm.listActive1, down: item.key < _vm.listActive1 },
              item.upDown,
              item.delay
            ]},[_c('img',{attrs:{"src":require(`@/assets/images/wallet/${item.url}`),"alt":""}})])}),0)]),_c('div',{staticClass:"world-page world-page-3",class:{ active: _vm.pageActive === 2, up: 2 < _vm.pageActive, down: 2 > _vm.pageActive }},[_c('ul',{staticClass:"world-page-list"},_vm._l((_vm.list[2]),function(item,key){return _c('li',{key:item.title,staticClass:"world-page-list-item",class:{ active: _vm.listActive2 === key },on:{"click":function($event){_vm.listActive2 = key},"mouseenter":_vm.stopTimeOut,"mouseleave":_vm.timeOut}},[_c('div',{staticClass:"world-page-list-bg",class:{ up: key > _vm.listActive2, down: key < _vm.listActive2 }}),_c('h3',{staticClass:"world-page-list-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"world-page-list-note common-text"},[_vm._v(_vm._s(item.note))])])}),0),_c('div',{staticClass:"world-page-img"},_vm._l((_vm.imgListMap[2]),function(item){return _c('div',{key:item.url,staticClass:"world-page-img-item",class:[
              { active: _vm.listActive2 === item.key, up: item.key > _vm.listActive2, down: item.key < _vm.listActive2 },
              item.upDown,
              item.delay
            ]},[_c('img',{attrs:{"src":require(`@/assets/images/wallet/${item.url}`),"alt":""}})])}),0)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }