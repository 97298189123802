<template>
  <div class="started">
    <h2 class="started-title">How To Get Started</h2>
    <ul class="started-list">
      <li class="started-list-item started-list-icon" v-for="item in list" :key="item.icon">
        <img :src="require(`@/assets/images/wallet/${item.icon}`)" alt="" />
      </li>
      <li class="started-list-item started-list-line">
        <img src="@/assets/images/wallet/wallet_04_line.png" alt="" />
      </li>
      <li class="started-list-item started-list-info" v-for="item in list" :key="item.title">
        <div class="started-list-note font-bold">{{ item.note }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Started',
  data() {
    return {
      list: [
        { icon: 'wallet_04_download.png', note: 'Download f(x)Wallet.' },
        {
          icon: 'wallet_04_create.png',
          note: 'Create a new wallet or import your existing wallet.'
        },
        { icon: 'wallet_04_experience.png', note: 'Experience the cross-chain verse.' }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.started {
  width: var(--content-width);
  margin-left: auto;
  margin-right: auto;
  padding: 56px 72px 80px;
  background-color: #fbfbfb;
  border-radius: 36px;
  .started-title {
    text-align: center;
  }
  .started-list {
    margin-top: 72px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto auto;
    grid-column-gap: 144px;
    justify-items: center;
  }
  .started-list-item {
  }
  .started-list-icon {
    width: 176px;
    position: relative;
    z-index: 1;
    &::before,
    &::after {
      content: '';
      position: absolute;
      border-radius: 500px;
      filter: blur(20px);
      z-index: -1;
    }
    &:nth-of-type(1)::before {
      width: 70px;
      height: 70px;
      top: 25px;
      left: 72px;
      background-color: #d5fff4;
    }
    &:nth-of-type(1)::after {
      width: 104px;
      height: 104px;
      top: 21px;
      left: 12px;
      background-color: #cfdfff;
    }
    &:nth-of-type(2)::before {
      width: 80px;
      height: 80px;
      top: 25px;
      left: 18px;
      background-color: #ffe2f7;
    }
    &:nth-of-type(2)::after {
      width: 112px;
      height: 112px;
      top: 1px;
      left: 59px;
      background-color: #e1daff;
    }
    &:nth-of-type(3)::before {
      width: 72px;
      height: 72px;
      top: 32px;
      left: 6px;
      background-color: #fff9cf;
    }
    &:nth-of-type(3)::after {
      width: 104px;
      height: 104px;
      top: -5px;
      left: 57px;
      background-color: #d5fff4;
    }
  }
  .started-list-line {
    grid-column: 1 / 4;
    grid-row: 2 / 3;
    width: 1047px;
    margin-top: -6px;
    position: relative;
    z-index: 1;
  }
  .started-list-info {
    text-align: center;
    position: relative;
    z-index: 1;
  }
  .started-list-title {
    margin-top: 15px;
  }
  .started-list-note {
    margin-top: 24px;
    font-size: 16px;
    color: var(--main);
    line-height: 24px;
    font-weight: 500;
  }
}
</style>
