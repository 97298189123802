<template>
  <div class="world">
    <div class="world-elevator">
      <div class="world-content">
        <h2 class="world-title">The most decentralized way to access web3 world</h2>
        <div
          class="world-page world-page-1"
          :class="{ active: pageActive === 0, up: 0 < pageActive, down: 0 > pageActive }"
        >
          <div class="world-page-img">
            <div
              v-for="item in imgListMap[0]"
              :key="item.url"
              class="world-page-img-item"
              :class="[
                { active: listActive0 === item.key, up: item.key > listActive0, down: item.key < listActive0 },
                item.upDown,
                item.delay
              ]"
            >
              <img :src="require(`@/assets/images/wallet/${item.url}`)" alt="" />
            </div>
          </div>
          <ul class="world-page-list">
            <li
              class="world-page-list-item"
              v-for="(item, key) in list[0]"
              :key="item.title"
              :class="{ active: listActive0 === key }"
              @click="listActive0 = key"
              @mouseenter="stopTimeOut"
              @mouseleave="timeOut"
            >
              <div class="world-page-list-bg" :class="{ up: key > listActive0, down: key < listActive0 }"></div>
              <h3 class="world-page-list-title">{{ item.title }}</h3>
              <div class="world-page-list-note common-text">{{ item.note }}</div>
            </li>
          </ul>
        </div>
        <div
          class="world-page world-page-2"
          :class="{ active: pageActive === 1, up: 1 < pageActive, down: 1 > pageActive }"
        >
          <ul class="world-page-list">
            <li
              class="world-page-list-item"
              v-for="(item, key) in list[1]"
              :key="item.title"
              :class="{ active: listActive1 === key }"
              @click="listActive1 = key"
              @mouseenter="stopTimeOut"
              @mouseleave="timeOut"
            >
              <div class="world-page-list-bg" :class="{ up: key > listActive1, down: key < listActive1 }"></div>
              <h3 class="world-page-list-title">{{ item.title }}</h3>
              <div class="world-page-list-note common-text">{{ item.note }}</div>
            </li>
          </ul>
          <div class="world-page-img">
            <div
              v-for="item in imgListMap[1]"
              :key="item.url"
              class="world-page-img-item"
              :class="[
                { active: listActive1 === item.key, up: item.key > listActive1, down: item.key < listActive1 },
                item.upDown,
                item.delay
              ]"
            >
              <img :src="require(`@/assets/images/wallet/${item.url}`)" alt="" />
            </div>
          </div>
        </div>
        <div
          class="world-page world-page-3"
          :class="{ active: pageActive === 2, up: 2 < pageActive, down: 2 > pageActive }"
        >
          <ul class="world-page-list">
            <li
              class="world-page-list-item"
              v-for="(item, key) in list[2]"
              :key="item.title"
              :class="{ active: listActive2 === key }"
              @click="listActive2 = key"
              @mouseenter="stopTimeOut"
              @mouseleave="timeOut"
            >
              <div class="world-page-list-bg" :class="{ up: key > listActive2, down: key < listActive2 }"></div>
              <h3 class="world-page-list-title">{{ item.title }}</h3>
              <div class="world-page-list-note common-text">{{ item.note }}</div>
            </li>
          </ul>
          <div class="world-page-img">
            <div
              v-for="item in imgListMap[2]"
              :key="item.url"
              class="world-page-img-item"
              :class="[
                { active: listActive2 === item.key, up: item.key > listActive2, down: item.key < listActive2 },
                item.upDown,
                item.delay
              ]"
            >
              <img :src="require(`@/assets/images/wallet/${item.url}`)" alt="" />
            </div>
          </div>
          <!--          <ul class="world-page-list">-->
          <!--            <li-->
          <!--              class="world-page-list-item"-->
          <!--              v-for="(item, key) in list[3]"-->
          <!--              :key="item.title"-->
          <!--              :class="{ active: listActive2 === key + 2 }"-->
          <!--              @click="listActive2 = key + 2"-->
          <!--              @mouseenter="stopTimeOut"-->
          <!--              @mouseleave="timeOut"-->
          <!--            >-->
          <!--              <div class="world-page-list-bg" :class="{ up: key + 2 > listActive2, down: key + 2 < listActive2 }"></div>-->
          <!--              <h3 class="world-page-list-title">{{ item.title }}</h3>-->
          <!--              <div class="world-page-list-note common-text">{{ item.note }}</div>-->
          <!--            </li>-->
          <!--          </ul>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ScrollTrigger } from '@/tools/gsap'
import { debounce } from 'lodash/function'

let resize = null
const ScrollTriggerList = []

export default {
  name: 'World',
  data() {
    return {
      pageActive: 0,
      listActive0: 0,
      listActive1: 0,
      listActive2: 0,
      timer: '',
      imgList: [
        [
          ['wallet_02_multi_1.png', 'wallet_02_multi_2.png'],
          ['wallet_02_cross_1.png', 'wallet_02_cross_2.png', 'wallet_02_cross_3.png'],
          ['wallet_02_slick_1.png', 'wallet_02_slick_2.png'],
          ['wallet_02_evm_1.png', 'wallet_02_evm_2.png', 'wallet_02_evm_3.png']
        ],
        [
          ['wallet_02_non_1.png', 'wallet_02_non_2.png', 'wallet_02_non_3.png'],
          ['wallet_02_biometric_1.png', 'wallet_02_biometric_2.png'],
          ['wallet_02_complete_1.png', 'wallet_02_complete_2.png'],
          ['wallet_02_advanced_1.png', 'wallet_02_advanced_2.png', 'wallet_02_advanced_3.png']
        ],
        [
          ['wallet_02_qris_1.png', 'wallet_02_qris_2.png', 'wallet_02_qris_3.png'],
          ['wallet_02_nft_1.png', 'wallet_02_nft_2.png'],
          ['wallet_02_yourgateway_1.png', 'wallet_02_yourgateway_2.png'],
          ['wallet_02_fiat_1.png', 'wallet_02_fiat_2.png']
        ]
      ],
      list: [
        [
          {
            title: 'Multi-Chain',
            note:
              'Support and hold multiple public chain assets. Equipped with a multi-address and blockchain management dashboard that enables one to switch addresses or blockchains back and forth seamlessly.'
          },
          {
            title: 'Cross-Chain',
            note: 'Experience seamless bridging from EVM-blockchains into f(x)Core and vice versa.'
          },
          {
            title: 'Slick and Interactive Design',
            note:
              'Visualize and customize your digital assets in a clean setting. You can even hide or block unwanted assets.'
          },
          {
            title: 'EVM Compatibility',
            note: 'Interact with any EVM-compatible networks and dApps.'
          }
        ],
        [
          {
            title: 'Non-Custodial',
            note:
              'Complete control and domination over your private keys and data. Your data and digital assets are only accessible to you.'
          },
          {
            title: 'Biometric Authentication',
            note:
              'A verification security mechanism that verifies the wallet owners on the unique biological characteristics of individuals.'
          },
          {
            title: 'Complete Privacy',
            note:
              'Your account addresses, asset balances, or any personally identifiable information are not tracked or recorded by f(x)Wallet.'
          },
          {
            title: 'Advanced Trustless Payment Solution',
            note:
              'Transact in cryptocurrencies directly from the app - Scan and Pay. It’s trustless and you own full authority of your own digital assets. This means you can send money to anyone, anywhere, at any time, without intermediaries or restrictions.'
          }
        ],
        [
          {
            title: 'QRIS Integration',
            note: 'Pay for your shopping needs with just a scan and tap at your own leisure.'
          },
          {
            title: 'NFT Gallery',
            note:
              'Focused on highlighting the best of your artwork and designs. Feature a curated selection of your best pieces and showcase them to your audience.'
          },
          {
            title: 'Your Gateway to Web3',
            note:
              'Support Wallet Connect, f(x)Wallet connect Web2 to Web3, enabling you to navigate the web3 world and explore a full suite of innovative dApps from a variety of blockchains at your fingertips.'
          },
          {
            title: 'Fiat On-Ramp',
            note:
              'Purchase any cryptocurrency with your credit or debit card instantly directly from your non-custodial wallet.'
          }
        ]
      ]
    }
  },
  computed: {
    imgListMap() {
      const imgListFlat = []
      this.imgList.map(item => {
        const list = []
        item.forEach((child, key) => {
          child.forEach((son, index) => {
            if (child.length === 3) {
              list.push({
                url: son,
                key,
                upDown: index === 1 ? 'img-shadow-down' : index === 2 ? 'img-shadow-up' : '',
                delay: index === 1 ? 'delay-1' : index === 2 ? 'delay-2' : ''
              })
            } else if (child.length === 2) {
              list.push({
                url: son,
                key,
                upDown: index === 0 ? 'img-shadow-down' : 'img-shadow-up',
                delay: index === 1 ? 'delay-1' : ''
              })
            } else {
              list.push({
                url: son,
                key,
                upDown: '',
                delay: ''
              })
            }
          })
        })
        imgListFlat.push(list)
      })
      return imgListFlat
    }
  },
  methods: {
    timeOut() {
      this.stopTimeOut()
      this.timer = setTimeout(() => {
        this[`listActive${this.pageActive}`] = (this[`listActive${this.pageActive}`] + 1) % 4
        this.timeOut()
      }, 3000)
    },
    stopTimeOut() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
    },
    resizeFc() {
      ScrollTrigger.refresh()
    }
  },
  mounted() {
    this.timeOut()
    document.fonts.ready.then(() => {
      ScrollTriggerList.push(
        ScrollTrigger.create({
          trigger: '.world',
          start: 'top top',
          end: 'bottom bottom',
          toggleActions: 'restart none reverse none',
          markers: process.env.NODE_ENV === 'development',
          onEnter: () => {
            this.$store.commit('setNavTransitionDelay', false)
            this.$store.commit('setBridgeResourceReady', false)
          },
          onLeave: () => {
            this.$store.commit('setNavTransitionDelay', false)
            this.$store.commit('setBridgeResourceReady', true)
          },
          onEnterBack: () => {
            this.$store.commit('setNavTransitionDelay', false)
            this.$store.commit('setBridgeResourceReady', false)
          },
          onLeaveBack: () => {
            this.$store.commit('setNavTransitionDelay', false)
            this.$store.commit('setBridgeResourceReady', true)
          }
        }),
        ScrollTrigger.create({
          trigger: '.world-elevator',
          start: 'top+=600 top',
          end: 'top+=600 top',
          toggleActions: 'restart none reverse none',
          markers: process.env.NODE_ENV === 'development',
          onEnter: () => {
            this.stopTimeOut()
            this.pageActive = 1
            this.timeOut()
          },
          onEnterBack: () => {
            this.stopTimeOut()
            this.pageActive = 0
            this.timeOut()
          }
        }),
        ScrollTrigger.create({
          trigger: '.world-elevator',
          start: 'top+=1200 top',
          end: 'top+=1200 top',
          toggleActions: 'restart none reverse none',
          markers: process.env.NODE_ENV === 'development',
          onEnter: () => {
            this.stopTimeOut()
            this.pageActive = 2
            this.timeOut()
          },
          onEnterBack: () => {
            this.stopTimeOut()
            this.pageActive = 1
            this.timeOut()
          }
        })
      )
    })
    resize = debounce(this.resizeFc, 300)
    window.addEventListener('resize', resize)
    resize()
  },
  beforeDestroy() {
    window.removeEventListener('resize', resize)
    ScrollTriggerList.forEach(item => {
      item.kill()
    })
  }
}
</script>

<style scoped lang="scss">
@mixin responsive($large, $medium, $small) {
  @media screen and (min-height: 1080px) {
    @content ($large);
  }
  @media screen and (min-height: 901px) and (max-height: 1079px) {
    @content ($medium);
  }
  @media screen and (max-height: 900px) {
    @content ($small);
  }
}
$cardHeightLarge: 1040px;
$cardHeightMedium: 860px;
$cardHeightSmall: 660px;
.world {
  height: calc(100vh + 1800px);
  //margin-top: calc(-500px);
  @include responsive(large, medium, small) using ($type) {
    @if $type == large {
      margin-top: calc(-500px - ((100vh - $cardHeightLarge) / 2));
    }
    @if $type == medium {
      margin-top: calc(-500px - ((100vh - $cardHeightMedium) / 2));
    }
    @if $type == small {
      margin-top: calc(-500px - ((100vh - $cardHeightSmall) / 2));
    }
  }
  position: relative;
  .world-elevator {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
  }
  .world-content {
    background-color: #fff;
    border-radius: 36px;
    width: 1200px;
    padding-left: 48px;
    padding-right: 48px;
    background-image: url('~@/assets/images/wallet/wallet_02_bg.jpg');
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: top right;
    position: relative;
    @include responsive(large, medium, small) using ($type) {
      @if $type == large {
        height: $cardHeightLarge;
      }
      @if $type == medium {
        height: $cardHeightMedium;
      }
      @if $type == small {
        height: $cardHeightSmall;
      }
    }
  }
  .world-title {
    width: 696px;
    @include responsive(large, medium, small) using ($type) {
      @if $type == large {
        margin-top: 64px;
      }
      @if $type == medium {
        margin-top: 48px;
        font-size: 60px;
        line-height: 50px;
      }
      @if $type == small {
        margin-top: 32px;
        font-size: 52px;
        line-height: 44px;
      }
    }
  }
  .world-page {
    position: absolute;
    top: 232px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s, transform 0.3s;
    @include responsive(large, medium, small) using ($type) {
      @if $type == large {
        top: 232px;
      }
      @if $type == medium {
        top: 175px;
      }
      @if $type == small {
        top: 101px;
      }
    }
    &.active {
      opacity: 1;
      pointer-events: initial;
    }
    &:nth-of-type(1) {
      padding-left: 48px;
      padding-right: 48px;
    }
    &:nth-of-type(2) {
      padding-left: 48px;
      padding-right: 48px;
    }
    &:nth-of-type(3) {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
  .world-page-img {
    position: relative;
    @include responsive(large, medium, small) using ($type) {
      @if $type == large {
        height: 760px;
        width: 560px;
      }
      @if $type == medium {
        height: 657px;
        width: 484px;
        margin-left: 30px;
        margin-right: 30px;
      }
      @if $type == small {
        height: 559px;
        width: 412px;
        margin-left: 82px;
        margin-right: 82px;
      }
    }
  }
  .world-page-img-item {
    opacity: 0;
    &.active {
      transition: transform 0.3s, opacity 0.3s;
      opacity: 1;
    }
    &:not(:nth-of-type(1)) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    img {
      display: block;
      width: 100%;
    }
  }
  .world-page-list {
    width: 512px;
    flex-shrink: 0;
    flex-grow: 0;
    padding-top: 32px;
  }
  .world-page:nth-of-type(3) {
    .world-page-list {
      display: grid;
      width: 100%;
      grid-template-rows: auto auto;
      grid-template-columns: 328px 328px;
      grid-column-gap: 480px;
      grid-row-gap: 56px;
      align-items: start;
      grid-auto-flow: column;
      padding-top: 160px;
    }
    .world-page-list-item {
      margin-top: 0;
    }
    .world-page-img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      margin-left: 0;
      margin-right: 0;
      width: 416px;
      @include responsive(large, medium, small) using ($type) {
        @if $type == large {
          width: 416px;
        }
        @if $type == medium {
          width: 360px;
        }
        @if $type == small {
          width: 306px;
        }
      }
    }
  }
  .world-page-list-item {
    border-radius: 4px;
    transition: background-color 0.3s;
    position: relative;
    cursor: pointer;
    @include responsive(large, medium, small) using ($type) {
      @if $type == large {
        padding: 24px;
      }
      @if $type == medium {
        padding: 20px 24px;
      }
      @if $type == small {
        padding: 18px 24px;
      }
    }
    &:not(:nth-of-type(1)) {
      @include responsive(large, medium, small) using ($type) {
        @if $type == large {
          margin-top: 32px;
        }
        @if $type == medium {
          margin-top: 24px;
        }
        @if $type == small {
          margin-top: 16px;
        }
      }
    }
    &:not(.active):hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
    .world-page-list-bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 1;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 4px;
        border: 2px solid rgba(8, 10, 50, 1);
        background-color: #fff;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translate(4px, 4px);
        background-image: url('~@/assets/images/wallet/wallet_02_pointshadow.png');
        background-size: 512px 512px;
      }
    }
    &.active .world-page-list-bg {
      opacity: 1;
      pointer-events: initial;
    }
    &:not(.active) .world-page-list-bg {
      opacity: 0;
      pointer-events: none;
    }
  }
  .up {
    transform: translateY(-10px);
  }
  .down {
    transform: translateY(10px);
  }
  .delay-1.active {
    transition-delay: 0.1s;
  }
  .delay-2.active {
    transition-delay: 0.2s;
  }
  .world-page-list-bg {
    transition: transform 0.3s, opacity 0.3s;
  }
  .world-page-list-title {
    position: relative;
    @include responsive(large, medium, small) using ($type) {
      @if $type == large {
      }
      @if $type == medium {
        font-size: 32px;
        line-height: 32px;
      }
      @if $type == small {
        font-size: 24px;
        line-height: 24px;
      }
    }
  }
  .world-page-list-note {
    position: relative;
    @include responsive(large, medium, small) using ($type) {
      @if $type == large {
        margin-top: 8px;
      }
      @if $type == medium {
        margin-top: 4px;
      }
      @if $type == small {
        margin-top: 2px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .img-shadow-up {
    filter: drop-shadow(16px 24px 24px rgba(8, 10, 50, 0.08));
  }
  .img-shadow-down {
    filter: drop-shadow(2px 2px 8px rgba(8, 10, 50, 0.06));
  }
}
</style>
