<template>
  <div class="fx-wallet">
    <div class="fx-wallet-bg">
      <div class="fx-wallet-bg-cloud">
        <img src="@/assets/images/wallet/wallet_01_cloud.svg" alt="" />
      </div>
      <div class="fx-wallet-bg-sun">
        <img src="@/assets/images/wallet/wallet_01_planet.svg" alt="" />
      </div>
      <div class="fx-wallet-bg-lake">
        <picture>
          <source srcset="@/assets/images/wallet/wallet_01_lake.avif" type="image/avif" />
          <img src="@/assets/images/wallet/wallet_01_lake.jpg" alt="" />
        </picture>
      </div>
      <div class="fx-wallet-bg-build">
        <img src="@/assets/images/wallet/wallet_01_building.png" alt="" />
      </div>
      <div class="fx-wallet-bg-light">
        <div class="fx-wallet-bg-light-line"></div>
        <div class="fx-wallet-bg-light-line"></div>
        <div class="fx-wallet-bg-light-line"></div>
        <div class="fx-wallet-bg-light-line"></div>
        <div class="fx-wallet-bg-light-line"></div>
      </div>
      <div class="fx-wallet-bg-block">
        <div class="fx-wallet-bg-block-img">
          <img src="@/assets/images/wallet/wallet_01_fx.png" alt="" />
        </div>
      </div>
    </div>
    <div class="fx-wallet-content common-content">
      <div class="common-transition-box">
        <transition name="transition-title">
          <h1 class="fx-wallet-title" v-show="show">
            f(x)Wallet
          </h1>
        </transition>
      </div>
      <transition name="transition-text">
        <div class="fx-wallet-info common-text" v-show="show">
          Decentralized, non-custodial, private key-supported multi-chain wallet. Manage, transfer, and grow your
          digital assets with different DApps, including DeFi protocols.
          <a
            href="https://agreement.functionx.io/en/fxWallet/termOfService.html"
            target="_blank"
            rel="noopener noreferrer"
            class="fx-wallet-service common-text"
            >TERMS OF SERVICE</a
          >
          <div class="fx-wallet-download">
            <CPoptip
              v-for="(item, key) of download"
              :key="item.title"
              placement="bottom"
              :shadow="'dark'"
              :hover="hoverIndex === key"
              @mouseenter="stop"
            >
              <ButtonDownload
                :link="item.link"
                :icon="item.icon"
                :title="item.title"
                :note="item.note"
                :min-width="'144px'"
                :shadow="'dark'"
                :hover="hoverIndex === key"
              ></ButtonDownload>
              <template v-slot:pop>
                <div class="common-qr-box"><QRCode :text="item.link"></QRCode></div>
                <div class="common-qr-info common-text-qr">Scan to install</div>
              </template>
            </CPoptip>
            <CPoptip placement="bottom" :shadow="'dark'" :hover="hoverIndex === 2" @mouseenter="stop">
              <TextDownload :margin="'4px'" />
              <template v-slot:pop>
                <div class="common-qr-box">
                  <QRCode :text="downloadAPK"></QRCode>
                </div>
                <div class="common-qr-info common-text-qr">Scan to download</div>
              </template>
            </CPoptip>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { gsapDestroy } from '@/tools/mixin'
import { animeTitleShow } from '@/tools/utils'
import { mapState } from 'vuex'
import CPoptip from '@/components/common/CPoptip.vue'
import ButtonDownload from '@/components/common/ButtonDownload.vue'
import QRCode from '@/components/common/QRCode.vue'
import TextDownload from '@/components/common/TextDownload.vue'
import { EventBus } from '@/tools/bus'

let timer

export default {
  name: 'FxWallet',
  components: { TextDownload, QRCode, ButtonDownload, CPoptip },
  data() {
    return {
      hoverIndex: -1,
      show: false
    }
  },
  mixins: [gsapDestroy],
  computed: {
    ...mapState(['download', 'downloadAPK'])
  },
  methods: {
    start() {
      if (this.hoverIndex < 3) {
        const delay = this.hoverIndex === -1 ? 500 : 2000
        timer = setTimeout(() => {
          this.hoverIndex += 1
          this.start()
        }, delay)
      }
    },
    stop() {
      if (timer) {
        clearTimeout(timer)
      }
      this.hoverIndex = -1
    }
  },
  mounted() {
    document.fonts.ready.then(() => {
      // 字体加载完成后的逻辑
      this.show = true
      this.$store.commit('setBridgeResourceReady', true)
    })
    this.commonGsapArray[0] = animeTitleShow({
      titleEl: this.$refs.title,
      textEl: this.$refs.text,
      scrollTrigger: {
        trigger: this.$refs.titleBox,
        start: 'bottom+=50 bottom',
        end: 'bottom+=50 bottom'
      },
      move: 'left'
    })
    EventBus.$on('fx-wallet-hover', () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      this.stop()
      this.start()
    })
  },
  beforeDestroy() {
    this.stop()
  }
}
</script>

<style scoped lang="scss">
.fx-wallet {
  min-height: 1440px;
  position: relative;
  .fx-wallet-content {
    padding-top: 68px;
    position: relative;
  }
  .fx-wallet-title {
    line-height: 140px;
  }
  .fx-wallet-info {
    max-width: 624px;
    margin-top: -12px;
  }
  .fx-wallet-service {
    margin-top: 10px;
    display: inline-block;
    text-decoration: underline;
  }
  .fx-wallet-download {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 32px;
    & > div {
      margin-right: 12px;
    }
  }

  .fx-wallet-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background-image: linear-gradient(180deg, #ffffff 0%, #a4bdff 51%, #e7eeff 76%, #ffffff 100%);
  }
  .fx-wallet-bg-cloud {
    width: 1222px;
    position: absolute;
    top: 134px;
    left: 50%;
    transform: translateX(-392px);
  }
  .fx-wallet-bg-sun {
    position: absolute;
    width: 424px;
    top: 476px;
    right: 50%;
    transform: translateX(-215px);
  }
  .fx-wallet-bg-lake {
    position: absolute;
    width: 1800px;
    top: 729px;
    left: 50%;
    transform: translateX(-50%);
  }
  .fx-wallet-bg-build {
    position: absolute;
    width: 1800px;
    top: 288px;
    left: 50%;
    transform: translateX(-50%);
  }
  .fx-wallet-bg-light {
    position: absolute;
    width: 172px;
    height: 334px;
    top: 0;
    left: 50%;
    transform: translateX(192px);
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 100%);
    overflow: hidden;
  }
  .fx-wallet-bg-light-line {
    position: absolute;
    top: 100%;
    background-image: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    border-radius: 2px;
    width: 3px;
    animation: 2s line-run linear infinite;
    &:nth-of-type(1) {
      height: 72px;
      left: 18px;
      animation-delay: 1s;
    }
    &:nth-of-type(2) {
      height: 80px;
      left: 47px;
    }
    &:nth-of-type(3) {
      height: 56px;
      left: 60px;
      animation-delay: 1.5s;
    }
    &:nth-of-type(4) {
      height: 40px;
      left: 118px;
      animation-delay: 0.5s;
    }
    &:nth-of-type(5) {
      height: 72px;
      left: 147px;
      animation-delay: 1.2s;
    }
  }
  .fx-wallet-bg-block {
    position: absolute;
    width: 138px;
    top: 130px;
    left: 50%;
    transform: translateX(209px);
    animation: 3s block-move ease-in-out alternate infinite;
  }
  .fx-wallet-bg-block-img {
    animation: 60s block-rotate ease-in-out alternate infinite;
  }
}

@keyframes line-run {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-406px);
  }
}

@keyframes block-rotate {
  0% {
    transform: rotate(-50deg);
  }
  100% {
    transform: rotate(50deg);
  }
}

@keyframes block-move {
  from {
    transform: translate(209px, 0);
  }
  to {
    transform: translate(209px, 20px);
  }
}
</style>
