<template>
  <Default>
    <div class="wallet">
      <FxWallet></FxWallet>
      <World></World>
      <Gateway></Gateway>
      <Started></Started>
      <Partners></Partners>
    </div>
  </Default>
</template>

<script>
import Default from '@/components/layout/Default.vue'
import FxWallet from '@/components/wallet/FxWallet.vue'
import World from '@/components/wallet/World.vue'
import Gateway from '@/components/wallet/Gateway.vue'
import Started from '@/components/wallet/Started.vue'
import Partners from '@/components/wallet/Partners.vue'

export default {
  name: 'Wallet',
  components: { Partners, Started, Gateway, World, FxWallet, Default }
}
</script>

<style scoped></style>
